export default {
  wells: [],
  wellIds: [
    {
      wellId: null,
      wellboreIds: [
        {
          wellboreId: null,
          wellboreState: null,
          wellboreName: null,
          logs: [
            {
              logName: null,
              tableName: null,
              logId: null,
            },
          ],
        },
      ],
    },
  ],
  tagValues: {},
  displayChanged: false,
  logData: {},
  isLoading: false,
  usersData: {
    subEntities: [],
    roleType: [],
    data: [],
  },
  userInfo: {},
  access_all: [],
  is_super_admin: false,
  replayDetails: {},
  myBooleanValue: false,
  customerLogo: "",
  tabNumber: "",
  isVendor: true,
  themeDetails: {},
  selectedVedor:null,
  customerList: [],
  selectedCustomerDetails: null,
};
